import { Link } from "gatsby";
import * as React from "react";
import { Dropdown } from "./dropdown/Dropdown";
import { NavItem } from "./NavItem";
import "./nav.scss";

const items = [
  { name: "Über Mich", link: "/uebermich/" },
  { name: "Aktuelles", link: "/aktuelles/" },
  {
    name: "Hunde",
    link: "/hunde/",
    /*items: [
      { name: "Zuchthündinen", link: "/hunde#zuchthunde" },
      { name: "Im Ruhestand", link: "/hunde#ruhestand" },
      { name: "Unvergessen", link: "/hunde#unvergessen" },
    ],*/
  },
  {
    name: "Zucht",
    items: [
      { name: "Wurfplanung", link: "/zucht/wurfplanung/" },
      { name: "Würfe", link: "/zucht/wuerfe/" },
      //{ name: "Aufzucht", link: "/zucht/aufzucht/" },
      { name: "Nachzucht", link: "/zucht/nachzucht/" },
    ],
  },
  {
    name: "Info",
    items: [
      { name: "Welpenkauf", link: "/info/welpenkauf/" },
      { name: "Welpenwünsche", link: "/info/welpenwuensche/" },
      { name: "Dogs mit Job", link: "/info/dogs-with-job/" },
      { name: "Besuchshunde", link: "/info/besuchshunde/" },
    ],
  },
  //{ name: "Gallerie", link: "/gallerie/" },
  { name: "Links", link: "/links/" },
  { name: "Kontakt", link: "/kontakt/" },
];

export const Nav = () => (
  <nav className="navbar navbar-expand-lg navbar-dark bg-primary sticky-top shadow-lg">
    <div className="container-fluid">
      <Link className="navbar-brand brand-logo fs-2" to="/">
        Golden Eyes of Munroe
      </Link>
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarNavDropdown">
        <ul className="navbar-nav mx-auto">
          {items.map((item) => {
            if (item.items) {
              return <Dropdown name={item.name} items={item.items} />;
            } else {
              return <NavItem name={item.name} link={item.link} />;
            }
          })}
        </ul>
      </div>
    </div>
  </nav>
);
