import { Link } from "gatsby";
import * as React from "react";

export const DropdownItem = ({ link, name }) => (
  <li>
    <Link className="dropdown-item" to={link} activeClassName="active">
      {name}
    </Link>
  </li>
);
