import * as React from "react";
import { Footer } from "./footer/Footer";
import { Header } from "./header/Header";
import { Nav } from "./navigation/Nav";
import { Seo } from "./seo";

export const Layout = ({ children, image, title, description }) => (
  <>
    <Nav />
    <Seo title={title} description={description} />
    <Header image={image} />
    {children}
    <Footer />
  </>
);
