import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import './header.scss'

export const Header = ({image}) => {
    if(image) {
        return <header className="mb-4"><GatsbyImage quality={80} style={{height: '450px'}} image={image} alt="Header Bild"/></header>
    } else {
        return <header className="mb-4"><StaticImage quality={80} style={{height: '450px'}} src="./home.jpg" alt="Header Bild"/></header>
    }
    
}

