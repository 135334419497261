import * as React from "react";
import { Link } from "gatsby";
import grc from "./grc.gif";
import drc from "./drc.png";
import vdh from "./vdh.svg";
import fci from "./fci.svg";

const clubs = [
  { name: "GRC", link: "https://grc.de", img: grc },
  { name: "DRC", link: "https://drc.de", img: drc },
  { name: "VDH", link: "https://vdh.de", img: vdh },
  { name: "FCI", link: "http://fci.be", img: fci },
];

export const Footer = () => (
  <footer className="footer bg-primary py-3 text-white mt-5">
    <div className="mx-auto d-flex justify-content-evenly flex-wrap">
      {clubs.map((club) => (
        <a href={club.link} target="_blank" rel="noreferrer">
          <img
            className="image-fluid"
            src={club.img}
            alt={club.name}
            height="80"
            loading="lazy"
          />
        </a>
      ))}
    </div>
    <div className="p-3 d-flex justify-content-center text-center">
      <div>
        © {new Date().getFullYear()} www.westermanns.com |&nbsp;
        <Link className="link-light" to="/kontakt/">
          Impressum
        </Link>
      </div>
    </div>
  </footer>
);
