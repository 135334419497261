import { Link } from "gatsby";
import * as React from "react";

export const NavItem = ({name, link}) => (
  <li className="nav-item">
    <Link className="nav-link" to={link} activeClassName="active">
      {name}
    </Link>
  </li>
);
