import * as React from "react";
import { DropdownItem } from "./DropdownItem";

export const Dropdown = ({name, items}) => (
  <li class="nav-item dropdown">
    <a
      class="nav-link dropdown-toggle"
      href="/"
      id="navbarDropdownMenuLink"
      role="button"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      {name}
    </a>
    <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
        {items.map(item => <DropdownItem link={item.link} name={item.name}/>)}
    </ul>
  </li>
);
